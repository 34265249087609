import { render, staticRenderFns } from "./christmas.vue?vue&type=template&id=61ce8298&scoped=true&"
import script from "./christmas.vue?vue&type=script&lang=js&"
export * from "./christmas.vue?vue&type=script&lang=js&"
import style0 from "./christmas.vue?vue&type=style&index=0&id=61ce8298&prod&scoped=true&lang=css&"
import style1 from "./christmas.vue?vue&type=style&index=1&id=61ce8298&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61ce8298",
  null
  
)

export default component.exports